import { template as template_23b40de56fc84717a6c5301fb4a42826 } from "@ember/template-compiler";
const FKControlMenuContainer = template_23b40de56fc84717a6c5301fb4a42826(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
