import { template as template_905728e06cfb4c2fa97ee4ab349df61e } from "@ember/template-compiler";
const FKLabel = template_905728e06cfb4c2fa97ee4ab349df61e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
