import { template as template_312b7baf1f5e4eaf8e47464a5db6977d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_312b7baf1f5e4eaf8e47464a5db6977d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
